import React, { useState } from "react";
import axios from "axios";

export default function ContactForm() {
  const inputs = ["First Name", "Last Name", "Email", "Phone Number"];
  const categoryOptions = [
    "Trip",
    "Vehicle/Driver",
    "KYC Verification",
    "Vehicle Registration",
    "Seat Booking",
    "Payment",
    "Others"
  ];

  const [inputValues, setInputValues] = useState({});

  const fetchData = async (url, reqBody) => {
    try {
      const response = await axios.post(url, reqBody);
      const responseData = response.data;
      return responseData;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const sendMail = async () => {
    try {
      if(!validateValues()) {
        return;
      }
      let url = "https://7mii22odud.execute-api.ap-south-1.amazonaws.com/bola-dev-test-stage/send-mail";
      let reqBody = { subject: selectedCategory, body: `${inputValues["Message"]} \n\n${inputValues[inputs[0]]} ${inputValues[inputs[1]]} \n${inputValues[inputs[2]]} \n${inputValues[inputs[3]]}` , to_email : "devhimangshu@gmail.com" }; 
      let response = await fetchData(url, reqBody);
      // if(response && response.response_code == 200) {} 
      // else {}
    } catch (error) {}
  }

  const validateValues = () => {
    if(!validateValue(inputValues[inputs[0]]) || !validateValue(inputValues[inputs[1]])) {
      document.getElementById("validationMessage").textContent = "Both first name and last name are required.";
      return false;
    }
    if(!validateEmail(inputValues[inputs[2]])) {
      document.getElementById("validationMessage").textContent = "Please enter valid mail address.";
      return false;
    }
    if(!validatePhoneNumber(inputValues[inputs[3]])) {
      document.getElementById("validationMessage").textContent = "Please enter valid phone number address.";
      return false;
    }
    if(!validateCategory()) {
      document.getElementById("validationMessage").textContent = "Please select valid category from the avaible options.";
      return false;
    }
    if(!validateValue(inputValues["Message"])) {
      document.getElementById("validationMessage").textContent = "Please enter message in the input box.";
      return false;
    }
    return true;
  }

  const validateValue = (value) => {
    if(value == undefined || value == "") {
      return false;
    }
    return true;
  }

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(String(email).toLowerCase());
  }

  const validatePhoneNumber = (phoneNumber) => {
    if(phoneNumber) {
      const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
      const validPhoneNumberPattern = /^\d{10}$/;
      return validPhoneNumberPattern.test(cleanedPhoneNumber);
    }
    return false;
  }

  const validateCategory = () => {
    if(!selectedCategory) {
      return false;
    }
    return true;
  }

  const handleInputChange = (e, item) => {
    const { name, value } = e.target;
    const updatedInputValues = { ...inputValues };
    updatedInputValues[item] = value;
    setInputValues(updatedInputValues);
  };

  const [selectedCategory, setSelectedCategory] = useState();

  const handleRadioChange = (event) => {
    console.log(event.target.value);
    setSelectedCategory(event.target.value);
  };

  return (
    <div className="col-md-7 col-10 p-2">
      <div id="validationMessage" style={{color: "red"}}></div>
      <div className="row justify-content-between align-items-center">
        {inputs.map((item, index) => (
          <div key={index} className="col-md-5 col-12">
            <div className="p-3">
              <div className="contact-input-label">{item}</div>
              <input className="contact-input-box p-1" width={"95%"} value={inputValues[item] || ''}  name={item} onChange={(e) => handleInputChange(e, item)} />
            </div>
          </div>
        ))}
      </div>
      <div className="p-3">
        <div className="contact-input-label text-bold">Select Category</div>
        <div className="row py-2">
          {categoryOptions.map((item, index) => (
            <div
              className="col-md-3 col-6 row justify-content-between"
              key={index}
            >
              <div className="col-2">
                <input type="radio" name="category" value={item} checked={selectedCategory === item} onChange={handleRadioChange}/>
              </div>
              <div className="col-10 contact-input-label">{item}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="p-3">
        <div className="contact-input-label">Message</div>
        <input className="contact-input-box p-1" width={"95%"} value={inputValues["Message"] || ''}  name="Message" onChange={(e) => handleInputChange(e, "Message")} />
      </div>
      <div className="py-3">
        <button
          className="mx-3 btn bg-black text-white p-2"
          style={{ width: "95%" }}
          onClick={() => sendMail() }
        >
          SEND MESSAGE
        </button>
      </div>
    </div>
  );
}