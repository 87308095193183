import "../styles/home/Footer.css";
export default function Footer() {
  const socials = ["insta.png", "twitter.png", "facebook.png", "youtube.png"];
  const handleSocialMediaClick = (item) => {
    switch(item) {
      case socials[0]:
        window.open("https://instagram.com/bolaletsgo?utm_source=qr&igshid=MThlNWY1MzQwNA==", '_blank')
        break;
      case socials[1]:
        window.open("https://twitter.com/BolaOutsta98873?t=tOFmYafOL1e-ednRLeGcUw&s=08", '_blank')
        break;
      case socials[2]:
        window.open("https://www.facebook.com/profile.php?id=100063492626918&mibextid=ZbWKwL", '_blank')
        break;
      case socials[3]:
        window.open("https://youtube.com/@bolaoutstation9119?si=NqfzzbxA7Z9BIc3l", '_blank')
        break;
    }
  }
  return (
    <div className="p-md-5" style={{ backgroundColor: "#2B2B2B" }}>
      <div className="p-md-4 p-4 text-md-center text-start">
        <div className="row justify-content-center align-items-center py-3">
          <div className="col-md-8 col-sm-6 col-4 px-4 text-center">
            <img alt="icon" src="./assets/bola_big.png" className="img-fluid" />
          </div>
          <div className="d-md-none d-flex row col-6 text-center">
            <div className="col-12">
              <img
                className="img-fluid"
                alt="google-play"
                src="./assets/google_play_btn.png"
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                alt="google-play"
                src="./assets/app_store.png"
              />
            </div>
          </div>
        </div>
        <div className="py-3 footer-subtext">
          Let's Move Together
        </div>
        <div className="text-start text-light w-100 footer-input">
          <div className="py-3">GET IN TOUCH</div>
          <input
            placeholder="ENTER YOUR EMAIL"
            className="bg-transparent text-white p-2 border-light w-75"
          />
          <button
            className="btn btn-light p-2 w-25 border-light"
            style={{
              borderRadius: 0,
              fontWeight: 600,
              fontSize: 16,
              borderWidth: 2,
            }}
          >
            SEND
          </button>
        </div>
        <div className="row justify-content-between" style={{ marginTop: "20px" }}>
          <div
            className="footer-item col-4 text-center"
            role="button"
            onClick={() => {
              window.location.href = "/privacy-policy";
            }}
          >
            Privacy Policy
          </div>
          <div
            className="footer-item col-4 text-center"
            role="button"
            onClick={() => {
              window.location.href = "/terms-and-condition";
            }}
          >
            Terms and Conditions
          </div>
          <div
            className="footer-item col-4 text-center"
            role="button"
            onClick={() => {
              window.location.href = "/about-us";
            }}
          >
            About us
          </div>
        </div>
        <div className="row justify-content-between" style={{ marginTop: "20px" }}>
          <div
            className="footer-item col-4 text-center"
            role="button"
            onClick={() => {
              window.location.href = "/bola-ticket-cancellation-policy";
            }}
          >
            BOLA RIDE/POST CANCELLATION AND REFUND POLICY
          </div>


          {/* <div
            display="none"
            className="footer-item col-4 text-center"
            role="button"
            onClick={() => {
              window.location.href = "/ride-cancellation-policy";
            }}
          >
            Ride Cancellation Policy
          </div> */}
        </div>
        <div className="py-5 px-md-1 px-3 row justify-content-around">
          <div className="col-md-4 col-lg-3 col-sm-6 col-10 row justify-content-around align-items-center">
            {socials.map((item, index) => (
              <div className="col" key={index}>
                <div className="position-absolute align-items-center justify-content-center" style={{cursor: "pointer"}} onClick={() => handleSocialMediaClick(item)}>
                  <div>
                    <img
                      alt="social-ellipse"
                      className="img-fluid"
                      src="./assets/social_ellipse.png"
                    />
                  </div>
                  <div className="position-relative social-icons">
                    <img
                      alt="insta"
                      className="img-fluid"
                      src={"./assets/" + item}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
