import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./page/Home";
import AboutUs from "./page/AboutUs";
import Contact from "./page/Contact";
import MenuBar from "./component/MenuBar"
import TermsAndCondn from "./page/TermsAndCondn";
import PrivacyPolicy from "./page/PrivacyPolicy";
import BolaTicketCancellationPolicy from "./page/BolaTicketCancellationPolicy";
import RideCancellationPolicy from "./page/RideCancellationPolicy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="" element={<Home />}></Route>
        <Route exact path="about-us" element={<AboutUs />}></Route>
        <Route exact path="contact" element={<Contact />}></Route>
        <Route exact path="menu-bar" element={<MenuBar/>}></Route>
        <Route exact path="terms-and-condition" element={<TermsAndCondn/>}></Route>
        <Route exact path="privacy-policy" element={<PrivacyPolicy/>}></Route>
        <Route exact path="bola-ticket-cancellation-policy" element={<BolaTicketCancellationPolicy/>}></Route>
        <Route exact path="ride-cancellation-policy" element={<RideCancellationPolicy/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
