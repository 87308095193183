import "../../styles/home/Trust.css";
export default function Trust() {
  const trust_points = [
    "Identity Verification",
    "Address Verification",
    "Document Validation",
    "User Authentication",
    "Continual Monitoring",
  ];
  return (
    <div className="py-5 ">
      <div className="text-center">
        <div
          className="d-inline-block text-center py-md-5 py-3 mx-md-2 mx-5 border-bottom"
          style={{ fontWeight: 600, fontSize: "2.5rem", borderWidth: 1 }}
        >
          TRUST AND TRANSPARENCY
        </div>
      </div>
      <div className="row justify-content-center align-items-center">
        <div className="col-12 px-5 py-3 d-lg-none d-flex trust-subtext">
          Our primary goal of KYC verification is to ensure the authenticity of
          users' identities and to mitigate potential risks related to fraud,
          money laundering, and other illegal activities.
        </div>
        <div className="col-lg-5 col-12 text-lg-end text-center p-3">
          <img className="img-fluid" src="./assets/trust.png" alt="trust" />
        </div>
        <div className="col-lg-7 col-12 px-md-2 px-2">
          <div className="d-lg-flex d-none trust-subtext overflow-hidden">
            Our primary goal of KYC verification is to ensure the authenticity
            of users' identities and to mitigate potential risks related to
            fraud, money laundering, and other illegal activities.
          </div>
          <div className="py-3 px-lg-1 px-5 row">
            {trust_points.map((point, index) => (
              <div className="col-lg-12 col-md-6 col-12 py-3" key={index}>
                <div className="row">
                  <div className="col-lg-1 col-md-2 col-3">
                    <img
                      className="img-fluid"
                      src="./assets/trust_tick_icon.png"
                      alt="trust_point"
                    />
                  </div>
                  <div className="col-lg-11 col-md-8 col-9">{point}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
