import "../../styles/Content.css"

export default function TermsAndCondnContent() {
  return (
    <div className="page-content">
        <h2 style={{overflow: "hidden"}}>Terms and Conditions</h2>
        <br />

        <p>EFFECTIVE FROM AUGUST 1, 2023 IF THIS DOCUMENT IS NOT IN A LANGUAGE THAT YOU UNDERSTAND, YOU SHALL
        CONTACT BOLA AT LEGAL AGREEMENTS@BOLALETSGO.COM. FAILURE TO DO SO WITHIN 12 (TWELVE) HOURS FROM THE TIME 
        OF RECEIPT OF THIS DOCUMENT AND YOUR ACCEPTANCE OF THIS DOCUMENT BY CLICKING ON THE ‘I ACCEPT’ BUTTON SHALL 
        BE CONSIDERED AS YOUR UNDERSTANDING OF THIS DOCUMENT.</p>

        <h3 style={{overflow: "hidden"}}>USER AGREEMENT</h3>
        <p>THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF INFORMATION TECHNOLOGY ACT, 2000 AND RULES THEREUNDER 
        AS APPLICABLE AND THE PROVISIONS PERTAINING TO ELECTRONIC RECORDS IN VARIOUS STATUTES AS AMENDED BY THE 
        INFORMATION TECHNOLOGY ACT, 2000. THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE 
        ANY PHYSICAL OR DIGITAL SIGNATURES. BY CLICKING ON THE “I ACCEPT” BUTTON ON THIS ELECTRONIC CONTRACT, YOU ARE 
        CONSENTING TO BE BOUND BY THIS SUBSCRIPTION AGREEMENT ALONG WITH THE VARIOUS EXHIBITS ATTACHED TO THE USER 
        AGREEMENT. PLEASE ENSURE THAT YOU READ AND UNDERSTAND ALL THE PROVISIONS OF THIS USER AGREEMENT AND EXHIBITS 
        BEFORE YOU START USING THE PORTAL, AS YOU SHALL BE BOUND BY ALL THE TERMS HEREIN UPON CLICKING ON THE 
        “ACCEPT & CONTINUE” BUTTON ON THIS ELECTRONIC CONTRACT. IF YOU DO NOT ACCEPT ANY OF THE TERMS CONTAINED HEREIN, 
        THEN PLEASE DO NOT USE THE WEBSITE/MOBILE APPLICATION OR AVAIL ANY OF THE SERVICES BEING PROVIDED THEREIN. 
        YOUR AGREEMENT TO THE USER AGREEMENT SHALL OPERATE AS A BINDING AGREEMENT BETWEEN YOU AND BOLA IN RESPECT OF THE 
        SERVICES OF THE WEBSITE/MOBILE APPLICATION.IT IS HEREBY EXPRESSLY CLARIFIED THAT THE PROVISIONS OF THIS 
        USER AGREEMENT WILL NOT APPLY TO YOUR SERVICES PROVIDED FOR ‘BOLA CORPORATE’ I.E., ARRANGEMENTS ENTERED INTO BY 
        BOLA WITH VARIOUS CORPORATE ENTITIES.</p>
        
        <p>This Agreement is made between BOLALETSGO TOURS AND TRAVELS PRIVATE LIMITED , a company registered under 
        the Companies Act, 1956 and having its corporate office at NAGAON, ASSAM India (hereinafter referred to as 
        “BOLA” which expression shall mean and include its representatives, successors- in–office, affiliates and 
        assigns) on the ONE PART; AND</p>

        <p>A mobility service provider who is a private car/taxi /bike operator, which provides empty seats for cost 
        sharing purpose but not for business  and is desirous of listing itself and its fleet of vehicles on the 
        website/mobile application, so as to provide mobility services through the Vehicle(s) to the users of “BOLA” 
        mobile application.</p>

        <h6 style={{overflow: "hidden"}}>OR</h6>
        
        <p>A person intended to use “bola” service for transportation purpose of the OTHER PART.</p>

        <p>BOLA and the Mobility Service Provider shall hereinafter individually be referred to as “Party” and 
        collectively as “Parties”. WHEREAS BOLA owns and operates an online market place called “BOLA” an online 
        empty seats booking platform, and any upgrades from time to time and any other software that enables the 
        use of the application or such other URL as may be specifically provided by BOLA (“Portal”) that lists and 
        aggregates the mobility service providers a registered with it.</p>

        <h5 style={{overflow: "hidden"}}>1) Indemnification</h5>
        
        <p><b>1.</b> i)The Mobility  Service Provider agrees and undertake to indemnify and to hold harmless BOLA and other 
        parties determined by BOLA, BOLA affiliates, successors, agents, assigns, and each of their directors, 
        officers, employees, associates, agents, and representatives from and against any losses, damages, liability, 
        claims, costs, penalty and expenses (including, without limitation, reasonable attorneys’ fees) incurred by 
        reason of (i) any breach or alleged breach by the Mobility  Service Provider of the Mobility Service Provider’s 
        obligations, responsibilities, representations, or warranties under the User Agreement and/or Mobility  Service 
        Provider T&C; (ii) breach of any service level commitments provided in Exhibit B;</p>

        <p>(ii) any infringement or unauthorized use of intellectual property rights of BOLA including but not limited 
        to infringement of intellectual property rights of BOLA in the Service Provider App or webiste</p>
        
        <p>(iii) any breach of the confidentiality obligations of the Mobility  Service Provider under this Agreement or Mobility Service 
        Provider T&C</p>
        
        <p>(iv) any violation of the applicable law,applicable license and permit terms of the trasnport authorities;</p>
        
        <p>(v) any violation of BOLA policies by the Mobility  Service Provider;</p>
        
        <p>(vi) any harm to the reputation and goodwill of BOLA directly attributable to the Mobility Service Provider;</p>
        
        <p>(vii) damage, unauthorized use or loss of the Service Provider App in the Device;</p>
        
        <p>(viii) death, fraud, theft, misconduct, negligence or deficiency of Mobility  Services by the Mobility 
        Service Provider; any negligent act or omission committed in the course of Mobility  Services hereunder, or 
        any misrepresentation made during the course of Mobility  Services hereunder;</p>
        
        <p>(ix) personal injury to or property damage of user of website/application including but not limited motor 
        accident claims, if any, asserted against BOLA and its associates by reason of the use and operation of Mobility 
        Service Provider’s Vehicle(S);</p>
        
        <p>(x) civil or criminal offense under law or in the opinion of BOLA;</p>
        
        <p>(xi) Failure of the Mobility Service Provider to make tax payments in accordance with applicable laws.</p>
        
        <p><b>1.</b>(i) The Mobility Service Provider shall be liable to indemnify and hold BOLA harmless against all damages, 
        losses, costs and expenses incurred by BOLA as a consequence of any complaint from any user of the Mobile 
        application/website received by BOLA with respect to deficient Mobility Services.</p>

        <p><b>1.</b>(ii) Notwithstanding anything contained in this Agreement, however, subject to applicable laws, the total 
        aggregate liability of BOLA under this Agreement or Mobility  Service Provider T&C whether in contract 
        (including in respect of the indemnity), tort (including negligence or breach of statutory duty), 
        misrepresentation, restitution or otherwise, arising out of or in connection with the performance or 
        contemplated performance of this Agreement shall be limited to INR 1000/- (Rupees One Thousand).</p>

        <h6 style={{overflow: "hidden"}}><b>1.</b>(iii) Specific Indemnity</h6>
        <p>The Car/bike owner/Driver or operator shall be solely liable for any and all accidents/incidents involving 
        the Vehicle, while providing the mobility Services. Bola shall not be held liable for any such accidents/ 
        incidents involving the Car/bile owner/Driver’s Vehicle. All miscellaneous expenses pertaining to the Vehicle, 
        such as maintenance expenditures, penalty for violation of traffic rules, etc., shall be borne solely by the 
        Owner/Driver, and Bola shall not be held liable or responsible for the same.</p>
        
        <h5 style={{overflow: "hidden"}}>2)ROLE OF BOLA</h5>
        <ul>
            <li><b>a)</b> Bola only provides a technology platform that connects intending travelers with Car/bike owner for 
            shared journey . It doesn’t operate any car/bike or offer the service of transportation to the User. Bola 
            also doesn’t act as an agent of any Car/Bike in the process of providing the above-mentioned technology 
            platform services.</li>
            <br />    
            <li><b>b)</b> The car/bike ticket booking voucher which “bola” issues to a User is solely based on the information 
            provided or updated by the car/bike regarding the seat availability/car details etc.</li>
            <br />
            <li><b>c)</b> The amenities, services, routes, fares, schedule, car/bike type, seat availability and any 
            other details pertaining to the journey are provided by the respective Car/Bike owner and “bola” has no 
            control over such information provided by the Car/bike owner.</li>
        </ul>

        <h5 style={{overflow: "hidden"}}>3) LIMITATION OF LIABILITY OF BOLA</h5>
        <p>In its role as a technology platform to enable transactions between the Car/bike owner and the Users for 
        sharing/booking of the empty seats, “Bola” shall not be responsible for the operations of the Car/Bike operator 
        including, but not limited to the following:</p>

        <ul>
            <li><b>a)</b> Timely departure or arrival of the Car/bike;</li>
            <br />
            <li><b>b)</b> The conduct of Car/bike owner;</li>
            <br />
            <li><b>c)</b> The condition of the Car, seats etc. not being up to the customer's expectation or as per 
            the description provided by the Car/bike operator;</li>
            <br />
            <li><b>d)</b> Cancellation of the trip due to any reasons;</li>
            <br />
            <li><b>e)</b> Loss or damage of the baggage of the customer;</li>
            <br />
            <li><b>f)</b> The Car/bike owner changing a customer's seat for any reason whatsoever;</li>
            <br />
            <li><b>g)</b> Car/Bike operator informing a wrong boarding point for the issuance of the booking 
            confirmation voucher, or changing such boarding point eventually with or without any notification to “bola” 
            or the User;</li>
        </ul>
        
        <h5 style={{overflow: "hidden"}}>4)RESPONSIBILITIES OF THE USERS</h5>
        <ul>
            <li><b>a)</b> Users are advised to call the car/bike operator to find out the exact boarding point, or any 
            information which they may need for the purpose of boarding or travel in that trip.</li>
            <br />
            <li><b>b)</b> At the time of boarding the car/bike, Users shall furnish a copy of the ticket, and any valid 
            identity proof like aadhar card, passport, PAN card or voter identification card or any other identity 
            proof issued by a government authority.</li>
            <br />
            <li><b>c)</b> Users are required to reach the boarding place at least 30 minutes before the scheduled departure 
            time.</li>
            <br />
            <li><b>d)</b> All tickets issued shall be non-transferable.</li>
        </ul>
        
        <h5 style={{overflow: "hidden"}}>5) CANCELLATION OF TICKET</h5>
        <ul>
            <li><b>a)</b> Cancellation of tickets can be done either through the mobile application, or by calling on 
            the customer care number;</li>
            <br />
            <li><b>b)</b> Any cancellation is subject to such cancellation charges as mentioned on the ticket.</li>
        </ul>
        
        <h5 style={{overflow: "hidden"}}>6) PAYMENT FOR BOOKING AND ANY ADDITIONAL CHARGES</h5>
        <ul>
            <li><b>a) FULL PAYMENT:</b> Payment maybe made in full amount to “bola” during the booking. Such total 
            booking amount includes the base fare, applicable taxes including GST as may be applicable as per local 
            laws, and any additional booking fee, service fee or convenience fee charged by “bola”.</li>
        </ul>
        
        <h5 style={{overflow: "hidden"}}>7) MISCELLANEOUS</h5>
        <ul>
            <li><b>a)</b> The car/bike operator shall solely be liable for compliance of all laws including but not 
            limited to the Motor Vehicle Act its Rules, applicable regulations, guidelines or directions enacted or 
            issued by the Central Government or relevant State Governments. Any prosecution arising out of the 
            contravention of such laws, rules, regulations, including but not limited to fines or penalties shall be 
            borne by the bus operator. The User agrees to take up any grievance resulting from cancellation or any 
            deficiency in services due to any action of the enforcement agencies arising with the car/bike operator.</li>
            <br />
            <li><b>b)</b> User Agreement and Privacy Policy at “bola” website shall apply. “bola” will be entitled to 
            reject any claim in case there is any abuse/misuse of the offer by the User or the cancellation/claim is 
            not eligible under the offer.</li>
            <br />
            <li><b>c)</b> The terms and conditions shall be governed by the laws of India. Any dispute arising out of or 
            in relation to this offer shall be subject to the exclusive jurisdiction of competent courts in Guwahati, 
            Assam.</li>
            <br />
            <li><b>d)</b> The maximum liability of “bola” in the event of any claim arising out of this offer shall not 
            exceed the amount under the underlying transaction paid by the User.</li>
            <br />
            <li><b>e)</b> “bola” shall not be liable to pay for any indirect, punitive, special, incidental or 
            consequential damages arising out of or in connection with the offer.</li>
        </ul>
    </div>
  );
}
