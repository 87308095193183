import Footer from "../component/Footer";
import Header from "../component/Header";
import TermsAndCondnContent from "../component/terms-and-condn/TermsAndCondnContent";

export default function TermsAndCondn() {
  return (
    <>
      <Header />
      <TermsAndCondnContent />
      <Footer />
    </>
  );
}
