export default function Values() {
  const values = [
    {
      img: "accessibility.png",
      head: "Reliability",
      text: "We are reliable both for customer and passenger through our strict verification process.",
      top: "50%",
      left: "50%",
    },
    {
      img: "safety.png",
      head: "Customer First",
      text: "Customer first.",
      top: "25%",
      left: "21%",
    },
    {
      img: "innovation.png",
      head: "Efficiency",
      text: "Increase the efficiency of journey by reducing cost.",
      top: "25%",
      left: "21%",
    },
    {
      img: "affordability.png",
      head: "Affordability",
      text: "Enjoy affordable rides with our app, where sharing the journey means sharing the savings.",
      top: "20%",
      left: "13%",
    },
  ];
  return (
    <>
      <div className="p-5 about-topics-heading">
        The story and values behind our company
      </div>
      <div className="px-5">
        <div className="row justify-content-center align-items-center">
          {values.map((item, index) => (
            <div
              className="col-md-6 col-12 justify-content-center align-items-center py-3 row"
              key={index}
            >
              <div className="col-3 text-center position-relative">
                <img
                  alt="black-box"
                  className="img-fluid"
                  src="./assets/about-us/topics-black-box.png"
                />
                <div className="values-internal-image">
                  <img
                    alt="black-box"
                    className="img-fluid"
                    src={"./assets/about-us/" + item.img}
                  />
                </div>
              </div>
              <div className="col-9 p-2">
                <div className="value-points-text overflow-hidden">
                  {item.head}
                </div>
                <div className="value-points-subtext">{item.text}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
