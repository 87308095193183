import Footer from "../component/Footer";
import Header from "../component/Header";
import AboutUsStory from "../component/home/AboutUsStory";
import AppStore from "../component/home/AppStore";
import CustomerReviews from "../component/home/CustomerReviews";
import Hero from "../component/home/Hero";
// import Offering from "../component/home/Offering";
import RideBro from "../component/home/RideBro";
import Trust from "../component/home/Trust";

export default function Home() {
  return (
    <>
      <Header />
      <Hero />
      <RideBro />
      <AboutUsStory />
      <Trust />
      {/* <Offering /> */}
      <AppStore />
      <CustomerReviews />
      <Footer />
    </>
  );
}
