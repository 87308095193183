import "../styles/home/MenuBar.css";

const MenuBar = () => {

    return (
        <div className="mobile-menu-window">
            <div
                className="row px-md-3 px-2 py-3 bg-black text-white align-items-center justify-content-between"
                style={{ fontSize: "1rem", width: "100%" }}
            >
                <div className="col-3 text-center">
                    <img
                        className="img-fluid"
                        src="./assets/bola_big.png"
                        alt="bola-logo"
                    />
                </div>
                <div className="col-7 text-end">
                    <button
                        className="btn px-2"
                        role="button"
                        onClick={() => {
                            window.location.href = "/";
                        }}
                    >
                        <img alt="nav" className="img-fluid" src="./assets/icons8-close-button-32.png" />
                    </button>
                </div>
            </div>
            <div style={{ backgroundColor: "#121212" }}>
                <div
                    className="item text-center"
                    role="button"
                    onClick={() => {
                        window.location.href = "/";
                    }}
                >
                    Home
                </div>
                <div
                    className="item text-center"
                    role="button"
                    onClick={() => {
                        window.location.href = "/about-us";
                    }}
                >
                    About Us
                </div>
                <div
                    className="item text-center"
                    role="button"
                    onClick={() => {
                        window.location.href = "/contact";
                    }}
                >
                    Contact
                </div>
                <div className="footer">
                    <div className="row justify-content-between">
                        <div
                            className="footer-item col-4 text-center"
                            role="button"
                            onClick={() => {
                                window.location.href = "/privacy-policy";
                            }}
                        >
                            Privacy Policy
                        </div>
                        <div
                            className="footer-item col-4 text-center"
                            role="button"
                            onClick={() => {
                                window.location.href = "/terms-and-condition";
                            }}
                        >
                            Terms and Conditions
                        </div>
                        <div
                            className="footer-item col-4 text-center"
                            role="button"
                            onClick={() => {
                                window.location.href = "/about-us";
                            }}
                        >
                            About us
                        </div>
                    </div>
                    <div className="row justify-content-between" style={{ marginTop: "20px" }}>
                        <div
                            className="footer-item col-4 text-center"
                            role="button"
                            onClick={() => {
                                window.location.href = "/bola-ticket-cancellation-policy";
                            }}
                        >
                            Bola Ticket Cancellation Policy
                        </div>
                        <div
                            className="footer-item col-4 text-center"
                            role="button"
                            onClick={() => {
                                window.location.href = "/ride-cancellation-policy";
                            }}
                        >
                            Ride Cancellation Policy
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MenuBar;
