export default function Hero() {
  return (
    <>
      <div className="pt-4 pb-2">
        <div className="text-center contact-main-text py-3">Contact us</div>
        <div className="text-center contact-main-subtext px-5 py-1">
          Any question or remarks? Just write us a message!
        </div>
      </div>
    </>
  );
}
