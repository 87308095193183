import Footer from "../component/Footer";
import Header from "../component/Header";
import BolaTicketCancellationPolicyContent from "../component/terms-and-condn/BolaTicketCancellationPolicyContent";

export default function BolaTicketCancellationPolicy() {
  return (
    <>
      <Header />
      <BolaTicketCancellationPolicyContent />
      <Footer />
    </>
  );
}
