import "../../styles/Content.css"

export default function BolaTicketCancellationPolicyContent() {
  return (
    <div className="page-content">
        <h2 style={{overflow: "hidden"}}>BOLA TICKET CANCELLATION POLICY</h2>
        <br />

        <p>Bola Booking/Post cancellation policy comes under the terms and conditions category. There is a full page of "BOLA" terms and conditions where you can find everything in full detail. If you face any difficulty in the future you can simply contact the customer service and enquire about anything you want. These policies are strictly abided by everyone who uses BOLA services. So, if you want to know more about the BOLA cancellation policy, here is some information.</p>

        <ul>
            <li><b>a)</b> You can cancel booking by logging into yours Bola mobile application User account.</li>
            <br />    
            <li><b>b)</b> •	If You shared empty seats and one or more booking completed against your empty seats, still you wish to cancel the ride than log in to your rider account of Bola and go to the active booking section and cancelled the ride.</li>
            <br />
            <li><b>c)</b> Cancellation charges will be added to every ticket cancellation/ ride cancellation.</li>
        </ul>

        <br />
        <h3 style={{overflow: "hidden"}}>BOLA RIDE CANCELLATION POLICY BOTH FOR DRIVER AND RIDER</h3>

        <p>There will be a certain cancellation amount that will be charged if you are deciding to go for ride/post  cancellation. If you have cancelled your ride/post, then the charges that are mentioned in bellow will apply. The following charges will apply as per BOLA ticket cancellation policy.</p>
        
        <br />
        <table className="custom-table">
            <thead>
                <tr>
                    <th><b>Booking Cancellation Time Period for rider</b></th>
                    <th><b>Charges</b></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>72 hours or more, prior to departure time </td>
                    <td>Free Cancellation </td>
                </tr>
                <tr>
                    <td>Between 72 hours and 48 hours of departure</td>
                    <td>25% of booking amount, will be deducted from the  booking amount</td>
                </tr>
                <tr>
                    <td>Between 48 hours and 24 hours of departure</td>
                    <td>Between 48 hours and 24 hours of departure	50% of booking amount, will be deducted from the  booking amount</td>
                </tr>
                <tr>
                    <td>Between 24 hours and 12 hours of departure</td>
                    <td>Between 24 hours and 12 hours of departure	75% of booking amount, will be deducted from the  booking amount</td>
                </tr>
                <tr>
                    <td>within 12 hours </td>
                    <td>within 12 hours 100% of booking amount, will be deducted from the  booking amount</td>
                </tr>
            </tbody>
        </table>

        <table className="custom-table">
            <thead>
                <tr>
                    <th><b>Ride Cancellation Time Period for Driver Charges</b></th>
                    <th><b>Charges</b></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>72 hours or more, prior to departure time </td>
                    <td>Free Cancellation </td>
                </tr>
                <tr>
                    <td>Between 72 hours and 48 hours of departure</td>
                    <td>Between 72 hours and 48 hours of departure	25% of the total booking amount, will be deducted from the next amount to be received by the Driver</td>
                </tr>
                <tr>
                    <td>Between 48 hours and 24 hours of departure</td>
                    <td>Between 48 hours and 24 hours of departure	50% of the total booking amount, will be deducted from the next amount to be received by the Driver</td>
                </tr>
                <tr>
                    <td>Between 24 hours and 12 hours of departure</td>
                    <td>Between 24 hours and 12 hours of departure	75% of the total booking amount, will be deducted from the next amount to be received by the Driver</td>
                </tr>
                <tr>
                    <td>within 12 hours </td>
                    <td>within 12 hours 100% of the total booking amount, will be deducted from the next amount to be received by the Driver </td>
                </tr>
            </tbody>
        </table>


        <br />
        <h3 style={{overflow: "hidden"}}>BOLA REFUND POLICY FOR RIDER</h3>

        <p>Customers are bound to have issues arise and will need the company to comply with their demands so that the company doesn't lose face. Refunds are a tough issue to walk around but there is no shortage of refund inquiries especially when the company is catering to a large number of people. Unfortunately, the refund depends on when you cancel your tickets and will be charged accordingly as mentioned in the above table. To know more about the BOLA refund policy, tracking and status, look below.</p>

        <ul>
            <li><b>a)</b> All the refund generally needs 7 (Seven) Business Days.</li>
            <br />    
            <li><b>b)</b> BOLA is only a facilitator and any refund for any service which is not delivered by the service provider or independent contractors or for any reason for which the User is entitled to a refund is subject to BOLA receiving the amount from the said service provider.</li>
            <br />
            <li><b>c)</b> The user should acknowledge that BOLA shall not be held liable for any delay in refund or non-refund of the amount from the respective service provider or Independent Contractors of BOLA. In such events, the User shall directly approach the service provider for any claims.</li>
            <br />
            <li><b>d)</b> In the event of any delay in the BOLA refund time beyond the period specified, the entire liability of BOLA shall be a refund of the said amount with interest calculated at the applicable bank rate till the date the refund is made.</li>
        </ul>
        
    </div>
  );
}
