import "../../styles/home/CustomerReview.css";

export default function CustomerReviews() {
  const reviews = [
    {
      text: "I used to dread my daily commute to work. But ever since I started using the carpooling app, my mornings have become stress-free. Not only do I save money on gas, but I also get to meet new people and have interesting conversations during the ride. It's a win-win situation for both my wallet and the environment!",
      name: "Kaushik",
      address: "Kamrup ,Assam, India",
    },
    {
      text: "As a busy professional, I appreciate the convenience that the carpooling app offers. It ensures I arrive at work on time without the hassle of driving in traffic. The app's easy-to-use interface and reliable scheduling make my daily commute much smoother.",
      name: "Mrinmay",
      address: "Rangia, Assam, India",
    },
    {
      text: "I can't thank this carpooling app enough for making my commute enjoyable. The app's user-friendly interface and secure payment options make the experience seamless. Plus, the drivers are always punctual and friendly. Highly recommended!",
      name: "Ashish",
      address: "Jorhat, Assam, India",
    },
  ];
  return (
    <div className="position-relative">
      <div className="background-container bola-bg-image"></div>
      <div className="position-absolute py-3 align-center text-white">
        <div className="reviews-title text-center p-3">CUSTOMER STORIES</div>
        <div className="review-subtitle text-center pt-4 pb-3">
          Hear from our satisfied customers and their experiences with us.
        </div>
        <div className="d-md-none d-block">
          <div
            className="mx-4 my-5 p-2 rounded text-start"
            style={{ backgroundColor: "#ddd" }}
          >
            <div>
              <img
                alt="doublt-quote"
                className="img-fluid"
                src="./assets/double-quotes.png"
              />
            </div>
            <div className="review-text px-3">
              I used to dread my daily commute to work. But ever since I started using the carpooling app, 
              my mornings have become stress-free. Not only do I save money on gas, but I also get to meet 
              new people and have interesting conversations during the ride. It's a win-win situation for 
              both my wallet and the environment!{" "}
            </div>
            <div className="py-2 px-3">
              <img
                src="./assets/review-partition.png"
                alt="parition"
                className="img-fluid"
              />
            </div>
            <div className="row px-3 align-items-center">
              <div className="col-2">
                <img
                  alt="profile"
                  src="./assets/review-profile.png"
                  className="img-fluid"
                />
              </div>
              <div className="col-10 p-2">
                <div className="review-profile-name">Kaushik</div>
                <div className="review-profile-address">Kamrup ,Assam, India</div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-5 d-md-flex d-none row justify-content-around">
          {reviews.map((item, index) => (
            <div
              className="p-2 rounded-4 text-start"
              style={{ backgroundColor: "#ddd", width: "30%" }}
            >
              <div>
                <img
                  alt="doublt-quote"
                  className="img-fluid"
                  src="./assets/double-quotes.png"
                />
              </div>
              <div className="review-text px-3">
                {item.text}{" "}
              </div>
              <div className="py-2 px-3">
                <img
                  src="./assets/review-partition.png"
                  alt="parition"
                  className="img-fluid"
                />
              </div>
              <div className="row px-3 align-items-center">
                <div className="col-2">
                  <img
                    alt="profile"
                    src="./assets/review-profile.png"
                    className="img-fluid"
                  />
                </div>
                <div className="col-10 p-2">
                  <div className="review-profile-name">{item.name}</div>
                  <div className="review-profile-address">{item.address}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
