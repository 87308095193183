import "../../styles/about-us/AboutUsHero.css";
export default function Hero() {
  return (
    <div className="py-4 ">
      <div className="text-center hero-main-text py-3">About us</div>
      <div className="text-center hero-main-subtext col-md-6 col-12 mx-auto">
        The “bola”, is the registered trade mark of Bolaletsgo Tours and Travels
        Private Limited. The Bolaletsgo Tours and Travels Private limited is a
        recognised start-ups by Govt. of India and Govt. of Assam. We are empty
        seats sharing platform for all cars and Bikes. We are working in Assam
        Since 2019 onwards in mobility sector and presently operating in whole
        Assam .
      </div>
      <div className="row px-1 py-md-4 py-2 justify-content-center">
        <div className="col-md-7 col-7 px-1">
          <img
            alt="hero-1"
            className="img-fluid"
            src="./assets/about-us/hero-img-1.jpg"
            width={"100%"}
            style={{borderRadius: "10px"}}
          />
        </div>
        <div className="col-md-5 col-5 px-1">
          <img
            alt="hero-2"
            className="img-fluid"
            src="./assets/about-us/hero-img-2.jpg"
            width={"100%"}
            style={{borderRadius: "10px", height: "100%"}}
          />
        </div>
      </div>
    </div>
  );
}
