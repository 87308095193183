import "../../styles/Content.css"

export default function RideCancellationPolicyContent() {
  return (
    <div className="page-content">
        <h2 style={{overflow: "hidden"}}>BOLA RIDE CANCELLATION POLICY</h2>
        <br />

        <h3 style={{overflow: "hidden"}}>BOLA RIDE CANCELLATION POLICY FOR DRIVER</h3>

        <p>There will be a certain cancellation amount that will be charged if you are deciding to go for ride 
        cancellation. If you have cancelled your ride voluntarily, then the charges that are mentioned in the brochure 
        or itinerary will apply. BOLA cancellation charges within 24 hours will charge you a certain percentage, 
        similarly the charges vary depending on when you want to cancel your ticket. The following charges will apply 
        as per BOLA ticket cancellation policy.</p>

        <br />
        <table className="custom-table">
            <thead>
                <tr>
                    <th><b>Time Period for Ride Cancellation</b></th>
                    <th><b>Charges</b></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>361 hours or more, prior to departure</td>
                    <td>Booking fees</td>
                </tr>
                <tr>
                    <td>Between 169 and 360 hours of departure</td>
                    <td>25% of booking amount, will be deducted from the next booking amount</td>
                </tr>
                <tr>
                    <td>Between 73 hours and 168 hours of departure</td>
                    <td>50% of booking amount, will be deducted from the next booking amount</td>
                </tr>
                <tr>
                    <td>Between 25 hours and 72hours of departure</td>
                    <td>75% of booking amount, will be deducted from the next booking amount</td>
                </tr>
                <tr>
                    <td>Within 24 hours prior to the date of departure no-show for any reason what-so-ever</td>
                    <td>100% of booking amount, will be deducted from the next booking amount</td>
                </tr>
            </tbody>
        </table>
        <br />
    </div>
  );
}
