export default function OurVision() {
  return (
    <div className="row justify-content-center align-items-center">
      <div className="col-md-9 col-12">
        <div className="py-3 px-5">
          <div className="position-relative overflow-hidden">
            <div className="py-1 px-2">
              <img
                alt="mission"
                className="img-fluid"
                src="./assets/about-us/vision-icon.png"
              />
            </div>
            <div
              className="hero-main-text py-3 position-absolute"
              style={{ top: 0, left: 45 }}
            >
              Our vission
            </div>
          </div>
        </div>
        <div className="text-center py-3 d-md-none d-block">
          <img
            alt="mission-icon"
            className="img-fluid"
            src="./assets/about-us/vision-img.png"
          />
        </div>
        <div className="p-5 mission-subtext">
          To create a smarter empty seats sharing platform which is efficient, 
          safe and economical thereby efficient use of empty seats, reduce cost
           and pollution of the world.
        </div>
      </div>
      <div className="text-center col-md-3 col-12 d-md-flex d-none">
        <img
          alt="mission-icon"
          className="img-fluid"
          src="./assets/about-us/vision-img.png"
        />
      </div>
    </div>
  );
}
