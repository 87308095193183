import Footer from "../component/Footer";
import Header from "../component/Header";
import ContactForm from "../component/contact/ContactForm";
import ContactInfo from "../component/contact/ContactInfo";
import Hero from "../component/contact/Hero";

import "../styles/Contact.css";

export default function Contact() {
  return (
    <>
      <Header />
      <Hero />
      <div className="row justify-content-center align-items-center py-md-4 py-1">
        <ContactInfo />
        <ContactForm />
      </div>
      <Footer />
    </>
  );
}
