import Footer from "../component/Footer";
import Header from "../component/Header";
import Hero from "../component/about-us/Hero";
import OurMission from "../component/about-us/OurMission";
import OurVision from "../component/about-us/OurVision";
import Values from "../component/about-us/Values";
import WhatWeDo from "../component/about-us/WhatWeDo";

export default function AboutUs() {
  return (
    <>
      <Header />
      <div className="px-md-5 mx-md-5 mx-0 px-1">
        <Hero />
        <WhatWeDo />
        <OurMission />
        <OurVision />
        <Values />
      </div>
      <Footer />
    </>
  );
}
