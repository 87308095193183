import "../../styles/Content.css"

export default function PrivacyPolicyContent() {
  return (
    <div className="page-content">
        <h2 style={{overflow: "hidden"}}>Privacy Policy</h2>
        <br />

        <p>Bolaletsgo Private Limited (hereinafter “bola”) recognizes the importance of privacy of its users and also 
        of maintaining confidentiality of the information provided by its users as a responsible data controller and 
        data processor.</p>

        <p>This Privacy Policy provides for the practices for handling and securing user's Personal Information 
        (defined hereunder) by bola and its subsidiaries and affiliates.</p>

        <p>This Privacy Policy is applicable to any person (‘User’) who purchases, intends to purchase, or inquire 
        about any product(s) or service(s) made available by “bola” through any of bola’s customer interface channels 
        including its website, mobile site, mobile app & offline channels including call centers and offices 
        (collectively referred herein as <b>"Sales Channels"</b>).</p>

        <p>For the purpose of this Privacy Policy, wherever the context so requires "you" or "your" shall mean User and 
        the term "we", "us", "our" shall mean “bola”. For the purpose of this Privacy Policy, online applications means 
        the website(s), mobile site(s) and mobile app(s).</p>

        <p>By using or accessing the Website/mobile application or other Sales Channels, the User hereby agrees with 
        the terms of this Privacy Policy and the contents herein. If you disagree with this Privacy Policy please do 
        not use or access our Mobile application /Website or other Sales Channels.</p>

        <p>This Privacy Policy is an integral part of your User Agreement with bola and all capitalized terms used, 
        but not otherwise defined herein, shall have the respective meanings as ascribed to them in the User Agreement.</p>

        <h4 style={{overflow: "hidden"}}>B. TYPE OF INFORMATION WE COLLECT AND ITS LEGAL BASIS</h4>

        <p>The information as detailed below is collected for us to be able to provide the services chosen by you and 
        also to fulfill our legal obligations as well as our obligations towards third parties as per our User Agreement.</p>
        
        <p><b>"Personal Information"</b> of User shall include the information shared by the User and collected by us 
        for the following purposes:</p>

        <ul>
            <li><b>a) Registration on the website/Mobile application:</b> Information which you provide while 
            subscribing to or registering on the Website/mobile application , including but not limited to information 
            about your personal identity such as name, gender, age etc., your contact details such as your email 
            address, postal addresses, telephone (mobile or otherwise) and/or fax numbers. The information may also 
            include information such as your PAN, AADHAR, banking details (including credit/debit card) and any other 
            information relating to your income and/or lifestyle; billing information payment history etc. (as shared 
            by you).</li>
            <br />
            <li><b>b) Other information:</b> We many also collect some other information and documents including but 
            not limited to:</li>
            <br />
            <li><b>c)</b> Transactional history (other than banking details) about your e-commerce activities, 
            buying behavior.</li>
            <br />
            <li><b>d)</b> Your usernames, passwords, email addresses and other security-related information used by 
            you in relation to our Services.</li>
            <br />
            <li><b>e)</b> Data either created by you or by a third party and which you wish to store on our servers 
            such as image files, documents etc.</li>
            <br />
            <li><b>f)</b> Data available in public domain or received from any third party including social media 
            channels, including but not limited to personal or non-personal information from your linked social media 
            channels (like name, email address, friend list, profile pictures or any other information that is 
            permitted to be received as per your account settings) as a part of your account information.</li>
            <br />
            <li><b>g)</b> Information pertaining any other traveler(s) for who you make a booking through your 
            registered bola account. In such case, you must confirm and represent that each of the other traveler(s) 
            for whom a booking has been made, has agreed to have the information shared by you disclosed to us and 
            further be shared by us with the concerned service provider(s).</li>
            <br />
            <li><b>h)</b> The Personal Information as aforesaid is essential to provide the services offered by bola. 
            You shall ensure that all the information, including but not limited to the Personal Information, provided 
            by you to us is true, correct, complete and genuine in all respects. You shall at all times have access to 
            the information provided and shall ensure to amend/correct/ update such information at the earliest through 
            the use of the Site or by contacting Us, if found to be inaccurate or deficient or outdated in its content. 
            We shall not be responsible for the authenticity of the Personal Information provided by you to Us. In no 
            event shall we be liable for any losses that you may incur due to the inaccurate or deficient information 
            or Personal Information provided by You to Us. You will however be fully liable to us and the authorities 
            for any inaccurate/ incomplete data or information provided by You.</li>
        </ul>

        <h4 style={{overflow: "hidden"}}>C. HOW WE USE YOUR PERSONAL INFORMATION</h4>

        <p>The Personal Information collected may be used in the following manner:</p>

        <h5 style={{overflow: "hidden"}}>1. While making a Registration:</h5>
        
        <p>While making a Registration in our website/application, we may use Personal Information such as Name, 
        Address, Age, Gender, email, car details etc may including, payment details which include cardholder name, 
        credit/debit card number (in encrypted form) with expiration date, banking details, wallet details etc. as 
        shared and allowed to be stored by you.</p>

        <h5 style={{overflow: "hidden"}}>2. While making a booking or post a ride:</h5>
        
        <p>While making a booking, we may use your Personal Information including, payment details which include 
        cardholder name, credit/debit card number (in encrypted form) with expiration date, banking details, wallet 
        details etc. as shared and allowed to be stored by you. We may also use the information of travellers list as 
        available in or linked with your account. This information is presented to the User at the time of making a 
        booking to enable you to complete your bookings expeditiously or post a ride in the bola.</p>

        <h5 style={{overflow: "hidden"}}>3. We may also use your Personal Information for several reasons including but not limited to:</h5>

        <ul>
            <li><b>a)</b> keep you informed of the post ride/ booking /transaction status;</li>
            <br />
            <li><b>b)</b> Your usernames, passwords, email addresses and other security-related information used by 
            you in relation to our Services.</li>
            <br />
            <li><b>c)</b> send booking confirmations either via SMS or Whatsapp or any other messaging service;</li>
            <br />
            <li><b>d)</b> send any updates or changes to your booking(s);</li>
            <br />
            <li><b>e)</b> allow our customer service to contact you, if necessary;</li>
            <br />
            <li><b>f)</b> customize the content of our website, mobile site and mobile app;</li>
            <br />
            <li><b>g)</b> request for reviews of products or services or any other improvements;</li>
            <br />
            <li><b>h)</b> send verification message(s) or email(s);</li>
            <br />
            <li><b>i)</b> Validate/authenticate your account and to prevent any misuse or abuse.</li>
        </ul>

        <h4 style={{overflow: "hidden"}}>D. SURVEYS:</h4>
        
        <p>We value opinions and comments from our Users and frequently conduct surveys, both online and offline. 
        Participation in these surveys is entirely optional. Typically, the information received is aggregated, and 
        used to make improvements to Website, other Sales Channels, services and to develop appealing content, features 
        and promotions for members based on the results of the surveys. Identity of the survey participants is anonymous 
        unless otherwise stated in the survey.</p>

        <h4 style={{overflow: "hidden"}}>E USER GENERATED CONTENT (UGC):</h4>

        <p>Bola provides an option to its users to post their experiences by way of review, ratings and general poll 
        questions. The customers also have an option of posting questions w.r.t a service offered by bola or post 
        answers to questions raised by other users. bola may also hire a third party to contact you and gather feedback 
        about your recent booking with bola. Though the participation in the feedback process is purely optional, you 
        may still receive emails, notifications (SMS, Whatsapp or any other messaging service) for you to share your 
        feedback. The reviews may be written or in a video format. The reviews written or posted may also be visible on 
        other travel or travel related platforms.</p>

        <p>The UGC that bola collect may be of the following kinds:</p>

        <ul>
            <li><b>a)</b> Review and Ratings</li>
            <br />
            <li><b>b)</b> Question and Answers</li>
            <br />
            <li><b>c)</b> Crowd Source Data Collection (poll questions).</li>
        </ul>

        <h4 style={{overflow: "hidden"}}>F. MARKETING PROMOTIONS, RESEARCH AND PROGRAMS:</h4>

        <p>Marketing promotions, research and programs help us to identify your preferences, develop programs and improve user experience. bola frequently sponsors 
        promotions to give its Users the opportunity to win great travel and travel related prizes. Personal Information collected by us for such activities may include 
        contact information and survey questions. We use such Personal Information to notify contest winners and survey information to develop promotions and product 
        improvements. As a registered User, you will also occasionally receive updates from us about fare sales in your area, special offers, new bola services, other 
        noteworthy items (like savings and benefits on bus tickets, hotel reservations, pilgrimage packages, car rentals and other travel services) and marketing 
        programs.</p>

        <p>In addition, you may look forward to receiving periodic marketing emails, newsletters and exclusive promotions offering special deals.</p>
        
        <p>From time to time we may add or enhance services available on the Mobile application/Website. To the extent these services are provided, and used by you, we 
        will use the Personal Information you provide to facilitate the service(s) requested. For example, if you email us with a question, we will use your email 
        address, name, nature of the question, etc. to respond to your question. We may also store such Personal Information to assist us in making the Website the 
        better and easier to use for our Users.</p>
        
        <p>Bola may from time to time launch reward programs by way of which users may stand to win travel related rewards or other rewards. We may use your Personal 
        Information to enroll you in the rewards program and status of the same will be visible each time you log in to the Mobile application/Website. Depending on the 
        reward program, each time you win a reward, bola may share your Personal Information with a third party that will be responsible for fulfilling the reward to 
        you. You may however choose to opt out of such reward programs by writing to us. For various purposes such as fraud detection, offering bookings on credit etc., 
        we at times may verify information of customers on selective basis, including their credit information.</p>

        <h4 style={{overflow: "hidden"}}>F. HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION OTHER RECORDS?</h4>
        
        <p>Bola will retain your Personal Information on its servers for as long as is reasonably necessary for the purposes listed in this policy. In some circumstances 
        we may retain your Personal Information for longer periods of time, for instance where we are required to do so in accordance with any legal, regulatory, tax or 
        accounting requirements.</p>

        <h4 style={{overflow: "hidden"}}>G. COOKIES AND SESSION DATA:</h4>
        <h5 style={{overflow: "hidden"}}>1. Cookies:</h5>
        
        <p>Bola uses cookies to personalize your experience on the Mobile application/Website and the advertisements that maybe displayed. bola’s use of cookies is 
        similar to that of any other reputable online companies.</p>

        <p>Cookies are small pieces of information that are stored by your browser on your device's hard drive. Cookies allow us to serve you better and more 
        efficiently. Cookies also allow ease of access, by logging you in without having to type your login name each time (only your password is needed); we may also 
        use such cookies to display any advertisement(s) to you while you are on the Website or to send you offers (or similar emails – provided you have not opted out 
        of receiving such emails) focusing on destinations which may be of your interest.</p>
        
        <p>A cookie may also be placed by our advertising servers, or third party advertising companies. Such cookies are used for purposes of tracking the effectiveness 
        of advertising served by us on any website, and also to use aggregated statistics about your visits to the Website in order to provide advertisements in the 
        Website or any other website about services that may be of potential interest to you. The third party advertising companies or advertisement providers may also 
        employ technology that is used to measure the effectiveness of the advertisements. All such information is anonymous. This anonymous information is collected 
        through the use of a pixel tag, which is an industry standard technology and is used by all major websites. They may use this anonymous information about your 
        visits to the Website in order to provide advertisements about goods and services of potential interest to you. No Personal Information is collected during this 
        process. The information so collected during this process, is anonymous, and does not link online actions to a User.</p>

        <p>Most web browsers automatically accept cookies. Of course, by changing the options on your web browser or using certain software programs, you can control 
        how and whether cookies will be accepted by your browser. bola supports your right to block any unwanted Internet activity, especially that of unscrupulous 
        websites. However, blocking bola cookies may disable certain features on the Website/mobile application, and may hinder an otherwise seamless experience to 
        purchase or use certain services available on the mobile application/Website. Please note that it is possible to block cookie activity from certain websites 
        while permitting cookies from websites you trust.</p>

        <h5 style={{overflow: "hidden"}}>2. Automatic Logging of Session Data:</h5>
        
        <p>Each time you access the mobile application/Website your session data gets logged. Session data may consist of various aspects like the IP address, operating 
        system and type of browser software being used and the activities conducted by the User while on the mobile application/Website. We collect session data because 
        it helps us analyze User’s choices, browsing pattern including the frequency of visits and duration for which a User is logged on. It also helps us diagnose 
        problems with our servers and lets us better administer our systems. The aforesaid information cannot identify any User personally. However, it may be possible to 
        determine a User's Internet Service Provider (ISP), and the approximate geographic location of User's point of connectivity through the above session data</p>
        
        <h4 style={{overflow: "hidden"}}>H. WITH WHOM YOUR PERSONAL INFORMATION IS SHARED:</h4>
        <h5 style={{overflow: "hidden"}}>1. Service Providers and suppliers:</h5>

        <p>Your information shall be shared with the end service providers like hotels, bus service providers, cab rental /car owner/ booking person or any other 
        suppliers who are responsible for fulfilling your booking or sharing the seats . You may note that while making a booking with bola or post a ride with bola, 
        you authorize us to share your information with the said service providers and suppliers. It is pertinent to note that bola does not authorize the end service 
        provider to use your information for any other purpose(s) except as may be for fulfilling their part of service. However, how the said service 
        providers/suppliers use the information shared with them is beyond the purview and control of bola as they process Personal Information as independent data 
        controllers, and hence we cannot be made accountable for the same. You are therefore advised to review the privacy policies of the respective service provider 
        or supplier whose services you choose to avail.</p>

        <p>Bola does not sell or rent individual customer names or other Personal Information of Users to third parties except sharing of such information with our 
        business / alliance partners or vendors who are engaged by us for providing various referral services and for sharing promotional and other benefits to our 
        customers from time to time basis their booking history with us.</p>

        <h6 style={{overflow: "hidden"}}><b>1.1. INFORMATION COLLECTED BY DRIVERS</b></h6>
        
        <p>This Privacy Policy shall not cover the usage of any information about you which is obtained by the driver or the company to which the driver belongs, while 
        providing you a ride on a cab booked using the Services, or otherwise, which is not provided by us.</p>

        <h5 style={{overflow: "hidden"}}>I. BUSINESS PARTNERS AND THIRD-PARTY VENDORS:</h5>

        <p>We may also share certain filtered Personal Information to our corporate affiliates or business partners who may contact the customers to offer certain 
        products or services, which may include free or paid products / services, which will enable the customer to have better travel experience or to avail certain 
        benefits specially made for bola customers. Examples of such partners are entities offering co-branded credit cards, travel insurance, insurance cover against 
        loss of wallet, banking cards or similar sensitive information etc. If you choose to avail any such services offered by our business partners, the services so 
        availed will be governed by the privacy policy of the respective service provider.</p>
        
        <p>Bola may share your Personal Information to third party that bola may engage to perform certain tasks on its behalf, including but not limited to payment 
        processing, data hosting, and data processing platforms.</p>
        
        <p>We use non-identifiable Personal Information of Users in aggregate or anonymized form to build higher quality, more useful online services by performing 
        statistical analysis of the collective characteristics and behavior of our customers and visitors, and by measuring demographics and interests regarding 
        specific areas of the mobile application/ Website. We may provide anonymous statistical information based on this data to suppliers, advertisers, affiliates and 
        other current and potential business partners. We may also use such aggregate data to inform these third parties as to the number of people who have seen and 
        clicked on links to their websites. Any Personal Information which we collect and which we may use in an aggregated format is our property. We may use it, in our 
        sole discretion and without any compensation to you, for any legitimate purpose including without limitation the commercial sale thereof to third parties.</p>
        
        <p>Occasionally, bola will hire a third party for market research, surveys etc. and will provide information to these third parties specifically for use in 
        connection with these projects. The information (including aggregate cookie and tracking information) we provide to such third parties, alliance partners, or 
        vendors are protected by confidentiality agreements and such information is to be used solely for completing the specific project, and in compliance with the 
        applicable regulations.</p>

        <h5 style={{overflow: "hidden"}}>J. DISCLOSURE OF INFORMATION</h5>

        <p>In addition to the circumstances described above, bola may disclose User's Personal Information if required to do so:</p>

        <li><b>a)</b> by law, required by any enforcement authority for investigation, by court order or in reference to any legal process;</li>
        <br />
        <li><b>b)</b> to conduct our business;</li>
        <br />
        <li><b>c)</b> for regulatory, internal compliance and audit exercise(s)</li>
        <br />
        <li><b>d)</b> to secure our systems; or</li>
        <br />
        <li><b>e)</b> to enforce or protect our rights or properties of bola or any or all of its affiliates, associates, employees, directors or officers or when we 
        have reason to believe that disclosing Personal Information of User(s) is necessary to identify, contact or bring legal action against someone who may be 
        causing interference with our rights or properties, whether intentionally or otherwise, or when anyone else could be harmed by such activities.</li>
        
        <p>Such disclosure and storage may take place without your knowledge. In that case, we shall not be liable to you or any third party for any damages howsoever 
        arising from such disclosure and storage.</p>
        
        <h5 style={{overflow: "hidden"}}>K. HOW CAN YOU OPT-OUT OF RECEIVING OUR PROMOTIONAL E-MAILS?</h5>
        
        <p>You will occasionally receive e-mail updates from us about fare sales in your area, special offers, new bola services, and other noteworthy items. We hope you 
        will find these updates interesting and informative. If you wish not to receive them, please click on the "unsubscribe" link or follow the instructions in each 
        e-mail message.</p>
        
        <h5 style={{overflow: "hidden"}}>L. PERMISSIONS REQUIRED FOR USING OUR MOBILE APPLICATIONS</h5>

        <p>When the bola app is installed on your phone or tablet, a list of permissions appear and are needed for the app to function effectively. There is no option to 
        customize the list. The permissions that bola requires and the data that shall be accessed and its use is as below:</p>

        <h6 style={{overflow: "hidden"}}><b>1. IOS/Android permissions:</b></h6>

        <ul>
            <li><b>a) Device and App history:</b> We need your device permission to get information about your device, like OS (operating system) name, OS version, 
            mobile network, hardware model, unique device identifier, preferred language, etc. Basis these inputs, we intend to optimize your travel booking 
            experience.</li>
            <br />
            <li><b>b) Identity:</b> This permission enables us to know about details of your account(s) on your mobile device. We use this info to auto-fill your email 
            ID’s and provide a typing free experience. It also helps us map email ID’s to a particular user to give you the benefit of exclusive travel offers, wallet 
            cash-backs, etc.</li>
            <br />
            <li><b>c) Location:</b> This permission enables us to give you the benefit of location specific deals and provide you a personalized experience. When you launch 
            bola app to make a travel booking, we auto-detect your location so that your nearest city is auto-filled. We also require this permission to be able to help you 
            track your bus with respect to your location.</li>
            <br />
            <li><b>d) SMS:</b> If you allow us to access your SMS, we read your SMS to autofill or prepopulate ‘OTP’ while making a transaction and to validate your mobile 
            number. This provides you a seamless purchase experience while making a booking and you don’t need to move out of the app to read the SMS and then enter it in 
            the app.</li>
            <br />
            <li><b>e) Phone:</b> The app requires access to make phone calls so that you can make phone calls to Ride provider/service operators, hotels and our 
            customer contact centers directly through the app.</li>
            <br />
            <li><b>f) Contacts:</b> If you allow us to access your contacts, it enables us to provide a lot of social features to you such as sharing tickets or 
            location with your friends.</li>
            <br />
            <li><b>g) Photo/ Media/ Files:</b> The libraries in the app use these permissions to allow users to save and upload multimedia reviews.</li>
            <br />
            <li><b>h) Wi-Fi connection information:</b> When you allow us the permission to detect your Wi-Fi connection, we optimize your bandwidth usage for multimedia 
            uploads.</li>
            <br />
            <li><b>i) Device ID and Call information:</b> This permission is used to detect your Apple/ Android ID through which we can uniquely identify users. It also 
            lets us know your contact details using which we pre-populate specific fields to ensure a seamless booking experience.</li>
            <br />
            <li><b>j) Camera:</b> This permission is used to capture pictures of the car/bike for making registration. This images can then uploaded as part of 
            multimedia reviews.</li>
            <br />
            <li><b>k) Calendar:</b> This permission enables us to put your travel plans on your calendar.</li>
        </ul>
        
        <h5 style={{overflow: "hidden"}}>M. HOW WE PROTECT YOUR PERSONAL INFORMATION?</h5>
        <p>All payments on the Mobile application/ Website are secured. This means all Personal Information you provide is transmitted using TLS (Transport Layer Security) 
        encryption. TLS is a proven coding system that lets your browser automatically encrypt, or scramble, data before you send it to us. Mobile application/ Website has 
        stringent security measures in place to protect the loss, misuse, and alteration of the information under our control. Whenever you change or access your account 
        information, we offer the use of a secure server. Once your information is in our possession we adhere to strict security guidelines, protecting it against 
        unauthorized access.</p>

        <h6 style={{overflow: "hidden"}}><b>1. Security</b></h6>
        
        <ul>
            <li><b>1.1.</b> We strive to ensure the security, integrity and privacy of Your Personal Information and to protect Your Personal Information against unauthorized 
            access or unauthorized alteration, disclosure or destruction. We adopt adequate measures to prevent unauthorized access to Your Personal Information.</li>
            <br />
            <li><b>1.2.</b> We reserve the right to conduct a security review at any time to verify your identity/details. you agree to provide us all the information 
            that we request for the security review. If you fail to comply with any security request, We reserve the right to terminate your Account with us, suspend 
            access to/use of your Account, and/or prohibit your access to the Site.</li>
            <br />
            <li><b>1.3.</b> We are not liable and cannot be held liable for any breach of security or for any actions of any third parties that receive Your Personal 
            Information.</li>
            <br />
            <li><b>1.4.</b> Notwithstanding anything contained in this Privacy Policy or elsewhere, we shall not be held responsible for any loss, damage or misuse of your 
            Personal Information, if such loss, damage or misuse is attributable to a Force Majeure Event (as defined below).</li>
            <br />
            <li><b>1.5.</b> A "Force Majeure Event" shall mean any event that is beyond Our reasonable control and shall include, without limitation, sabotage, fire, flood, 
            explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, unauthorised 
            access to computer data and storage device, computer crashes, breach of security and encryption, etc.</li>
        </ul>

        <h5 style={{overflow: "hidden"}}>N. WITHDRAWAL OF CONSENT AND PERMISSION</h5>

        <p>You may withdraw your consent to submit any or all Personal Information or decline to provide any permissions on its Mobile application/Website as covered above 
        at any time. In case, you choose to do so then your access to the Mobile Application/Website may be limited, or we might not be able to provide the services to you. 
        You may withdraw your consent by sending an email to privacy@bolaletsgo.com</p>

        <h5 style={{overflow: "hidden"}}>O. YOUR RIGHTS QUA PERSONAL INFORMATION</h5>

        <p>You may access your Personal Information from your user account with “bola”. You may also correct your personal information or delete such information 
        (except some mandatory fields) from your user account directly. If you don’t have such a user account, then you write to privacy@bolaletsgo.com</p>

        <h5 style={{overflow: "hidden"}}>P. CHANGES TO THE PRIVACY POLICY</h5>

        <p>We reserve the rights to revise the Privacy Policy from time to time to suit various legal, business and customer requirement. We will duly notify the users as 
        may be necessary.</p>
        
        <h5 style={{overflow: "hidden"}}>Q. ELIGIBILITY TO TRANSACT WITH “bola”</h5>

        <p>You must atleast 18 years of age with sound minds to transact directly with ‘bola’ and also to consent to the processing of your personal data.</p>
        <p>Any information that is freely available or accessible in public domain or furnished under the Right to Information Act, 2005 or any law for the time being in 
        force shall not be regarded as Personal Information within this Privacy Policy.</p>

        <h5 style={{overflow: "hidden"}}>R. Grievances</h5>
        
        <p>“bola” hereby appoints ‘bola’ Grievance Officer as the grievance officer for the purposes of the rules drafted under the Information Technology Act, 2000, who may 
        be contacted .We shall address your grievances with respect to processing of information provided in relation to this Privacy Policy/post ride/booking/payment etc in 
        a time bound manner. We will try to solve your issue in a timely manner and within 1 (One) month from the date of receipt of grievance.</p>

        <h6 style={{overflow: "hidden"}}>Grievance Officer:</h6>

        <ul>
            <li>Mr. S.D Sarma, <b>Email Address:</b> sankar@bolaletsgo.com</li>
        </ul>

        <p>If you not satisfy with the solution you may Contact seconf level of Grievance solution mechanism</p>

        <ul>
            <li>Mr. P.K.Kalita, <b>Email Address:</b> Prasanta@bolaletsgo.com</li>
        </ul>

        <p>Governing law and dispute resolution This Privacy Policy shall be governed by and construed in accordance with the laws of India. All disputes in relation to 
        the Privacy Policy will be adjudicated exclusively before a competent court in Guwahati, India only. <b>You may always submit concerns regarding this Privacy Policy 
        via email to us at privacy@bolaletsgo.com. bola shall endeavour to respond to all reasonable concerns and inquiries.</b></p>
    </div>
  );
}
