import Footer from "../component/Footer";
import Header from "../component/Header";
import PrivacyPolicyContent from "../component/privacy-policy/PrivacyPolicyContent";

export default function PrivacyPolicy() {
  return (
    <>
      <Header />
      <PrivacyPolicyContent />
      <Footer />
    </>
  );
}
