import "../../styles/home/AboutUsStory.css";

export default function AboutUsStory() {
  const imgs = [
    { img: "cost_savings_icon.png", title: "Cost Savings" },
    { img: "convenience_icon.png", title: "Convenience" },
    { img: "efficient_routing_icon.png", title: "Efficient Routing" },
    { img: "flexibility_icon.png", title: "Flexibility" },
  ];
  return (
    <div>
      <div className="d-md-flex d-none row py-5" style={{ marginTop: 60 }}>
        <div className="col-1"></div>
        <div
          className="col-11 row align-items-center p-5"
          style={{ backgroundColor: "#F9F9F9", borderTopLeftRadius: 420 }}
        >
          <div className="col-6">
            <img
              src="./assets/our_story_poster.png"
              alt="about-us"
              className="img-fluid"
            />
          </div>
          <div className="col-6 p-4">
            <div
              className="text-center"
              style={{
                fontSize: 50,
                fontWeight: 700,
              }}
            >
              OUR STORY
            </div>
            <div className="py-4" style={{ fontSize: 17 }}>
              During our Journey, we have seen commercial or personal car are running with one or more
              than one empty seats which increase the journey cost and increase the numbers of cars on 
              road as results increase the number of traffic, increase pollution and increase India’s 
              dependence on oil. Sometimes, it has also been seen in Airport/Railway station/Bus 
              stoppage, some cars are sharing their seats and pickup unverified passenger in their cars 
              which is very risky both for Driver and Passenger. Fare is fixed by the service provider 
              which also complicated for the driver/owner. In this scenario, the bola has introduced the
              first ever shared mobility platform.
            </div>
            {/* <div className="pt-3">
              <button
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                }}
                className="btn bg-black text-white py-3 px-5"
              >
                READ MORE
              </button>
            </div> */}
          </div>
        </div>
      </div>
      {/* mobile */}
      <div className="row d-md-none d-flex py-5">
        <div
          className="text-center col-12"
          style={{
            fontSize: 50,
            fontWeight: 700,
          }}
        >
          OUR STORY
        </div>
        <div style={{ backgroundColor: "#F9F9F9", borderTopRightRadius: 350 }}>
          <div className="col-12">
            <img
              alt="about-us"
              className="img-fluid reversed-image ps-5 py-5"
              src="./assets/our_story_poster.png"
              width={"100%"}
            />
          </div>
          <div className="col-12 px-4">
            <div className="py-1" style={{ fontSize: 17 }}>
              During our Journey, we have seen commercial or personal car are running with one or more
              than one empty seats which increase the journey cost and increase the numbers of cars on 
              road as results increase the number of traffic, increase pollution and increase India’s 
              dependence on oil.  Sometimes, it has also been seen in Airport/Railway station/Bus 
              stoppage, some cars are sharing their seats and pickup unverified passenger in their cars 
              which is very risky both for Driver and Passenger . Fare is fixed by the service provider 
              which also complicated for the driver/owner.  In this scenario, the bola has introduced the
              first ever shared mobility platform.
            </div>
            {/* <div className="py-3">
              <button
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                }}
                className="btn bg-black text-white py-3 px-5"
              >
                READ MORE
              </button>
            </div> */}
          </div>
        </div>
      </div>
      {/* mobile */}

      <div className="row text-center justify-content-center align-items-center about-us-icons ">
        {imgs.map((item, index) => (
          <div className="col-md-3 col-6 p-2 overflow-hidden" key={index}>
            <div className="px-auto text-center position-relative overflow-hidden">
              <div>
                <img
                  className="img-fluid"
                  alt="ellipse"
                  src="./assets/ellipse_icon.png"
                />
              </div>
              <div className="about-us-icon-over-ellipse ">
                <img
                  className="img-fluid"
                  alt={item.img}
                  src={"./assets/" + item.img}
                />
              </div>
              <div
                className="py-3"
                style={{
                  fontWeight: 500,
                  fontSize: 20,
                }}
              >
                {item.title}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
