export default function OurMission() {
  return (
    <div className="row justify-content-center align-items-center">
      <div className="col-12 col-md-9">
        <div className="">
          <div className="position-relative px-5 overflow-hidden">
            <div className="py-1 px-2">
              <img
                alt="mission"
                className="img-fluid"
                src="./assets/about-us/mission-target.png"
              />
            </div>
            <div
              className="hero-main-text py-3 position-absolute"
              style={{ top: 0, left: 90 }}
            >
              Our mission
            </div>
          </div>
        </div>
        <div className="text-center py-3 d-md-none d-block">
          <img
            alt="mission-icon"
            className="img-fluid"
            src="./assets/about-us/mission-icon.png"
          />
        </div>
        <div className="p-5 mission-subtext">
          Let’s Move together.
        </div>
      </div>
      <div className="text-center d-md-flex d-none col-md-3">
        <img
          alt="mission-icon"
          className="img-fluid"
          src="./assets/about-us/mission-icon.png"
        />
      </div>
    </div>
  );
}
