import "../../styles/Contact.css";

export default function ContactInfo() {
  return (
    <div className="col-md-4 col-10 bg-black text-white px-2 py-4 rounded text-center text-md-start px-md-4 py-md-5">
      <div className="contact-info-heading">Contact Information</div>
      <div className="contact-info-subheading">
        Say something to start a live chat!
      </div>
      <div className="row justify-content-center align-items-center pt-2 pb-3">
        <div className="col-12 col-md-2 col-lg-1 py-2">
          <img
            alt="call"
            className="img-fluid"
            src="./assets/contact/contact-call.png"
          />
        </div>
        <div className="col-12 col-md-10 col-lg-11 p-2 contact-info-option">
          +912345678901
        </div>
      </div>
      <div className="row justify-content-center align-items-center pb-3">
        <div className="col-12 col-md-2 col-lg-1 py-2">
          <img
            alt="call"
            className="img-fluid"
            src="./assets/contact/contact-email.png"
          />
        </div>
        <div className="col-12 col-md-10 col-lg-11 p-2 contact-info-option">
          Info@bolaletsgo.com
        </div>
      </div>
      <div className="row justify-content-center align-items-center  pb-3">
        <div className="col-12 col-md-2 col-lg-1 py-2">
          <img
            alt="call"
            className="img-fluid"
            src="./assets/contact/contact-location.png"
          />
        </div>
        <div className="col-12 col-md-10 col-lg-11 p-2 contact-info-option">
          India
        </div>
      </div>
      <div className="pt-5 pb-2 px-5">
        <div className="row px-5 justify-content-evenly align-items-center">
          <div className="col-md-6 col-sm-2 col-3 position-relative overflow-hidden" style={{cursor: "pointer"}} onClick={() => window.open("https://twitter.com/BolaOutsta98873?t=tOFmYafOL1e-ednRLeGcUw&s=08", '_blank')}>
            <img
              alt="social-ellipse"
              className="img-fluid"
              src="./assets/contact/contact-ellipse-dark.png"
            />
            <div className="position-absolute contact-info-social">
              <img
                alt="twitter"
                className="img-fluid"
                src="./assets/contact/contact-twitter.png"
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-2 col-3 position-relative overflow-hidden" style={{cursor: "pointer"}} onClick={() => window.open("https://instagram.com/bolaletsgo?utm_source=qr&igshid=MThlNWY1MzQwNA==", '_blank')}>
            <img
              alt="social-ellipse"
              className="img-fluid"
              src="./assets/contact/contact-ellipse-light.png"
            />
            <div className="position-absolute contact-info-social">
              <img
                alt="twitter"
                className="img-fluid"
                src="./assets/contact/contact-instagram.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
