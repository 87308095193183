import Footer from "../component/Footer";
import Header from "../component/Header";
import RideCancellationPolicyContent from "../component/terms-and-condn/RideCancellationPolicyContent";

export default function RideCancellationPolicy() {
  return (
    <>
      <Header />
      <RideCancellationPolicyContent />
      <Footer />
    </>
  );
}
