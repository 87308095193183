export default function WhatWeDo() {
  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-4  px-md-0 px-5">
        <div className="position-relative px-3 overflow-hidden">
          <div className="py-1">
            <img
              alt="question"
              className="img-fluid"
              src="./assets/about-us/question-icon.png"
            />
          </div>
          <div
            className="hero-main-text position-absolute"
            style={{ top: 7, left: 40 }}
          >
            What we do
          </div>
        </div>
        <div className="d-md-flex d-none ps-5 pt-3 col-10">
          <img
            alt="car-icon"
            className="img-fluid"
            src="./assets/about-us/car-icon.png"
          />
        </div>
      </div>
      <div className="col-md-8 col-12 p-2">
        <div className="position-relative overflow-hidden pb-5 pt-2">
          <div className="hero-main-subtext px-md-0 px-5 pb-5 pt-1">
            We provide a shared mobility platform where anyone after a procedure can share or book seats and make the journey together.
          </div>
          <div className="position-absolute car-icon d-md-none d-flex">
            <img
              alt="car-icon"
              className="img-fluid"
              src="./assets/about-us/car-icon-2.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
