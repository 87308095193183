import "../../styles/home/RideBro.css";

export default function RideBro() {
  return (
    <div
      className="text-white p-5 row justify-content-between align-items-center"
      style={{ backgroundColor: "#0E0E0E", marginTop: 60 }}
    >
      <div className="d-md-none d-inline-block col-12">
        <div className="text-center ride-main-heading">
          ON THE ROAD TOGETHER
        </div>
      </div>
      <div className="col-md-4 py-md-1 py-3 col-12 text-center">
        <img
          src="./assets/Order_ride-bro_1.png"
          alt="ride-bro"
          className="img-fluid"
        />
      </div>
      <div className="col-md-5 p-md-1 p-3 col-12 text-center">
        <div className="text-center py-4 d-md-inline-block d-none ride-main-heading">
          ON THE ROAD TOGETHER
        </div>
        <div className="ride-main-subheading text-start">
          We provide a shared mobility platform where anyone after a 
          procedure can share or book seats and make the journey together.{" "}
        </div>
      </div>
    </div>
  );
}
