import "../../styles/home/AppStore.css";
export default function AppStore() {
  return (
    <div className="p-5 text-white" style={{ backgroundColor: "#2b2b2b" }}>
      <div className="py-5 row justify-content-between align-items-between">
        <div
          className="col-md-5 col-12 p-md-5 p-1"
          style={{ color: "#d9d9d9" }}
        >
          <div className="app-store-heading">GET THIS APP</div>
          <div className="py-1">
            <img
              alt="dash"
              className="img-fluid"
              src="./assets/get-app-dash.png"
            />
          </div>
          <div className="app-store-subheading py-3">
            Download the App from playstore
          </div>
          <div className="row">
            <div className="col-5 text-end p-2">
              <img
                alt="google-app-store"
                className="img-fluid"
                src="./assets/google-play-btn-bg-1.png"
              />
            </div>
            <div className="col-5 p-2">
              <img
                alt="app-store"
                className="img-fluid"
                src="./assets/app-store-bg.png"
              />
            </div>
          </div>
        </div>
        <div className="col-md-5 text-md-end col-12">
          <div className="row py-5">
            <div className="col-md-4 col-0"></div>
            <div className="col-md-4 col-6 p-1 text-end">
              <img
                alt="bola-mobile-view"
                className="img-fluid"
                src="./assets/bola-mobile-view.png"
              />
            </div>
            <div className="col-md-4 col-6 p-1">
              <img
                alt="bola-mobile-map-view"
                className="img-fluid"
                src="./assets/bola-mobile-map-view.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
