export default function Header() {
  return (
    <div
      className="row px-md-3 px-2 py-3 bg-black text-white align-items-center justify-content-between"
      style={{ fontSize: "1rem" }}
    >
      <div className="col-md-2 col-3 text-center">
        <img
          className="img-fluid"
          src="./assets/bola_big.png"
          alt="bola-logo"
          style={{cursor: "pointer"}} 
          onClick={() => {window.location.href = "/";}}
        />
      </div>
      <div className="col-lg-4 col-md-6 d-md-block d-none">
        <div className="row justify-content-between">
          <div
            className="col-3 text-end"
            role="button"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Home
          </div>
          <div
            className="col-3 text-center"
            role="button"
            onClick={() => {
              window.location.href = "/about-us";
            }}
          >
            About Us
          </div>
          <div
            className="col-3 text-start"
            role="button"
            onClick={() => {
              window.location.href = "/contact";
            }}
          >
            Contact
          </div>
        </div>
      </div>
      <div className="col-md-2 col-7 text-end">
        <button 
          className="btn btn-light" 
          style={{ fontWeight: 600 }}
          onClick={() => {
            window.location.href = "https://play.google.com/store/apps/details?id=bola.user.webviewapp";
          }}
          >
          Download Now
        </button>


        <button 
          className="btn d-md-none d-inline-block px-2"
          role="button"
          onClick={() => {
            window.location.href = "/menu-bar";
          }}
        >
          <img alt="nav" className="img-fluid" src="./assets/nav-btn.png" />
        </button>
      </div>
    </div>
  );
}
