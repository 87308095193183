import "../../styles/home/Hero.css";

export default function Hero() {
  return (
    <div className="row justify-content-around align-items-center">
      <div className="col-lg-5 col-9 pt-lg-5 ps-lg-5 p-2">
        <div className="pt-lg-5 pt-0 ps-lg-4 ps-0">
          <div className="position-relative overflow-hidden">
            <img
              src="./assets/home_blob_dark.png"
              alt="err"
              className="img-fluid"
            />
            <div
              className="position-absolute"
              style={{ top: "3.3%", left: "3.3%" }}
            >
              <div className="position-relative overflow-hidden">
                <img
                  src="./assets/home_blob_light.png"
                  alt="err"
                  className="img-fluid"
                />
                <div className="position-absolute home-hero-main-div">
                  <div>
                    <img
                      src="./assets/address_pin.png"
                      alt="pin"
                      className="img-fluid"
                    />
                  </div>
                  <div className="py-1 ps-2 home-hero-main-text overflow-hidden">
                    Let's Move Together
                  </div>
                  <div className="py-2 ps-2 home-hero-main-subtext">
                    Embrace the Future of Mobility.
                  </div>
                  <div className="pt-md-2 ps-2">
                    <button className="btn px-md-5 py-md-3 px-sm-3 py-sm-2 p px-2 py-1  text-white bg-black home-hero-main-btn">
                      GET STARTED
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-2 d-lg-none d-flex"></div>
      <div className="col-2 d-lg-none d-flex"></div>
      <div className="col-lg-7 col-9 text-end pt-lg-5 pe-lg-5">
        <img
          className="img-fluid"
          src="./assets/home_hero_main.png"
          alt="hero_main"
        />
      </div>
    </div>
  );
}
